.navigation-upper {
  position: relative;
  min-height: 150px;

  &.promoted {
    height: 725px;
  }
}

.navigation {
  position: relative;
}

.main-navigation.home {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.main-navigation-inner {
  // border: 1px solid red;
  padding: 20px 20px;
  position: relative;

  .navigation-buttons {
    z-index: 20;
  }
}

.promoted-raffle-ticket-section {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  bottom: -200px;
  height: 225px;
  position: absolute;
  right: 20px;
  width: 225px;

  .progress-bar {
    .CircularProgressbar-path {
      stroke: #102964;
      stroke-linecap: round;
      stroke-width: 5px;
      transition-duration: 0.5s;
    }

    .CircularProgressbar-trail {
      stroke: rgba(255, 255, 255, 0.5);
      stroke-width: 12.5px;
      stroke-linecap: round;
    }

    .tickets {
      text-align: center;
      letter-spacing: unset;
      margin-top: -15px;

      .sold {
        color: #0E255C;
        font-size: 16px;
        font-weight: bold;
        line-height: 18px;
        margin-bottom: 0;
      }

      .total {
        color: #C3CFD8;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
}

.global-logo {
  display: inline-block;
  text-decoration: none;
  text-indent: -99999px;
  background-image: url("/img/pryze-textlogo.png");
  background-size: cover;
  background-position: center;
  width: 200px;
  height: 65px;
  z-index: 20;
}

@media screen and (max-width: $grid-max-width) {
  .promoted-raffle-text {
    left: 7.5% !important;
  }
}

@media screen and (max-width: 520px) {
  .promoted-raffle {
    .promoted-raffle-lower {
      font-size: 24px !important;
    }
  }
}

@media screen and (max-width: $mobile-break) {
  .global-logo {
    background-image: url("/img/pryze-logo.png") !important;
  }

  .navigation-upper {
    min-height: 120px;

    &.promoted {
      max-height: 575px;
    }
  }

  .promoted-raffle {
    height: 575px !important;

    .promoted-raffle-button {
      float: right;
      font-size: 28px !important;
      margin-top: unset !important;
    }

    .promoted-raffle-ticket-section {
      height: 150px !important;
      right: 5px !important;
      top: -155px !important;
      transform: unset !important;
      width: 150px !important;
    }
  }
}

.promoted-raffles-slider {
  height: 100%;

  .promoted-raffle-slide {
    position: relative;

    .promoted-raffle {
      position: relative;
      background-size: cover;
      background-position: 50% 50%;
      width: 100%;
      height: 725px;

      .promoted-raffle-button {
        background-color: white;
        border: transparent;
        border-radius: 50px;
        color: $text-color;
        cursor: pointer;
        font-size: 32px;
        font-weight: bold;
        margin-top: 10px;
        padding: 10px 25px;
        text-decoration: none;
        top: 20px;
      }

      .promoted-raffle-extra {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .promoted-raffle-lower {
        background-color: rgba(0, 0, 0, 0.35);
        bottom: 0;
        color: white;
        font-size: 32px;
        padding: 50px 20px;
        position: absolute;

        .promoted-raffle-lower-section {
          display: flex;
          grid-gap: 20px 20px;
          justify-content: space-between;
        }
      }

      .promoted-raffle-text {
        color: white;
        font-size: 48px;
        left: 125px;
        position: relative;
        width: 725px;
        text-shadow: 0 0 10px rgb(0, 0, 0, 0.45);
      }

      .promoted-section {
        margin: auto 0;

        .promoted-raffle-name {
          margin: auto 0;

          &.win {
            font-weight: bold;
          }
        }
      }
    }
  }
}

.dropdown {
  &.middle {
    a {
      font-weight: bold;
      text-decoration: none;

      svg {
        position: absolute;
        right: 5px;
      }
    }

    ul li {
      padding: 5px;
    }

    left: 50%;
    margin: 10px 0 0 0;
    transform: translateX(-50%);
  }

  &.open {
    opacity: 1;
    transition: 0.25s ease-in-out;
    z-index: 99999;
  }

  ul li {
    border-bottom: rgba(15, 41, 99, 0.2) 1px solid;
  }

  ul li:last-child {
    border-bottom: transparent 1px solid;
  }

  background: white;
  border-radius: 15px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  margin: 15px 0 0 -42.5px;
  transition: 0.25s ease-in-out;
  width: 150px;
}

ul.main-navigation-links {
  text-align: left;
  z-index: 9;

  li {
    display: inline-block;
    padding: 40.5px 30px;
    position: relative;

    &:last-child {
      float: right;
      padding-right: 0;
      padding-top: 31.5px;
    }

    &.subcategory {
      float: left;
      line-height: 1.4rem;
      padding: 15px;
      text-align: center;
      width: 100%;

      a {
        color: $text-color;
      }
    }
    
    .nav-chev {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: 15px;
      right: 7.5px;
    }

    a {
      color: #fff;
      text-decoration: none;
      font-weight: bold;
    }
  }

  .account-btn,
  .cart-btn {
    vertical-align: middle;
    display: inline-block;
  }

  .cart-btn {
    background-image: url("../../img/basket.svg");
    background-repeat: no-repeat;
    background-position: 50%;
    width: 37px;
    height: 37px;
    padding: 0;
    position: relative;
    margin-left: 10px;

    svg {
      width: 17px;
      height: 17px;
      margin: 9px 6px;
    }

    span.cart-quantity {
      position: absolute;
      background: white;
      height: 18px;
      font-size: 0.7rem;
      font-weight: bold;
      line-height: 18px;
      color: black;
      padding: 0 6px;
      border-radius: 50px;
      text-align: center;
      top: -6px;
      right: -8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    }
  }
}

.global-trustpilot-banner {
  background: #151726;

  .col {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.persistent-message {
  width: 100%;
  background-color: white;

  .col {
    display: flex;
    justify-content: space-between;
  }

  .message-left,
  .message-right {
    display: flex;
    align-items: center;
  }

  svg,
  span {
    vertical-align: top;
    margin-top: 4px;
  }

  svg {
    width: 18px;
    height: 18px;
    flex-shrink: 0;
  }

  span {
    margin-left: 15px;
    font-size: 1.1rem;
    line-height: 1.75rem;
  }

  button.resend-email {
    flex-shrink: 0;
    font-size: 0.8rem;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 1.5rem !important;
    padding: 5px 15px;
    border-radius: 100px;
    border: 0;
    outline: 0;
    cursor: pointer;
  }

  button.close-btn {
    margin-left: 15px;
    background: none;
    border: 0;
    outline: 0;
    color: black;
    cursor: pointer;
  }
}

.flash-message {
  position: fixed;
  display: flex;
  align-items: center;
  top: 0;
  left: 50%;
  width: 300px;
  transform: translateX(-50%);
  border-radius: 13px;
  box-shadow: 0px 3px 6px #00000029;
  padding: 20px;
  color: #111;
  background: $background-color;
  color: #fff;
  transition: 0.15s ease-in-out;
  z-index: 9999999999;
  cursor: pointer;

  &.error {
    background: $accent-red;
  }

  &.warning {
    background: $accent-yellow;
  }

  &.success {
    background: $accent-green;
  }

  svg {
    display: inline-block;
  }

  span {
    display: inline-block;
    margin-left: 10px;
    margin-top: 3px;
    line-height: 1.3rem;
  }

  .close-icon {
    position: absolute;
    top: 7px;
    right: 10px;
  }
}

// Main content container
.app-main-content {
  // border: 1px solid blue;
  // margin-top: 30px;
  overflow: hidden;
}

.app-main-footer {
  border-top-left-radius: 30px;
  text-align: center;
  padding: 50px 0;
  font-size: 0.9rem;
  color: white;

  .footer-logo {
    margin: 0 auto 30px auto;
    width: 170px;
    height: 55px;
    background: url("/img/pryze-textlogo.png") no-repeat;
    background-size: cover;
    background-position: center;
  }

  .links {
    margin-top: 5px;
    
    a {
      text-decoration: none;

      &:hover {
        transition: 0.2s ease-in-out;
      }
    }
  }

  .social-links {
    margin: 20px 0;

    .social-link {
      margin: 8px;

      &:hover {
        transition: 0.2s ease-in-out;
      }
    }
  }
}

.global-cookie-notice {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(calc(-50% - 20px));
  width: calc(100% - 40px);
  max-width: 800px;
  margin: 20px;
  border-radius: 13px;
  box-shadow: 0px 3px 6px #00000029;
  padding: 20px 40px 20px 65px;
  line-height: 1.5rem;
  color: #111;
  background: white;
  background-image: url("/img/cookies-graphic.svg");
  background-repeat: no-repeat;
  background-position: -20px;
  background-size: 70px;
  color: #222;
  transition: 0.15s ease-in-out;
  z-index: 9999999999;
  cursor: pointer;

  svg {
    display: inline-block;
  }

  span {
    display: inline-block;
    margin-left: 10px;
    margin-top: 3px;
    line-height: 1.3rem;
  }

  .close-icon {
    position: absolute;
    background: none;
    outline: 0;
    border: 0;
    padding: 10px;
    cursor: pointer;
    top: 0;
    right: 0;
  }
}

.global-promo-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;

  .global-promo-popup-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 999;
  }

  .global-promo-popup-modal {
    position: relative;
    width: 100%;
    max-width: 500px;
    height: auto;
    background: transparent;
    z-index: 9999;
    margin: 20px;

    .global-promo-popup-closebtn {
      position: absolute;
      right: -15px;
      top: -15px;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      font-weight: bold;
      background: $background-color;
      text-align: center;
      line-height: 40px;
      cursor: pointer;
      border: 0;
      color: white;

      svg {
        display: inline-block;
        width: 20px;
        height: 20px;
      }
    }

    .global-promo-popup-img {
      width: 100%;
      max-width: 500px;
      background-size: 100% auto;
      background-position: 50% 50%;
      margin: 0;
      display: block;
    }
  }
}

@media screen and (min-width: $mobile-break + 1) {
  ul.main-navigation-links {
    display: block;
  }
}

@media screen and (max-width: $mobile-break) {
  .persistent-message {
    /*
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999999;
    padding: 0px;
    border-radius: 25px 25px 0 0;
    */

    .col {
      flex-direction: column;
    }

    .message-left {
      padding-right: 15px;
    }

    .message-right {
      justify-content: center;
      margin-top: 20px;
    }

    .close-btn {
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }

  .main-navigation-inner {
    height: 100px;
  }

  .global-logo {
    margin: 0;
    position: absolute;
    top: 35px;
    left: 50%;
    transform: translateX(-50%);
    width: 168px;
    height: 50px;
  }

  .global-menu {
    position: absolute;
    left: 20px;
    top: 40px;
    color: white;
    background: none;
    border: 0;
    outline: 0;
    cursor: pointer;

    svg {
      width: 35px;
      height: 35px;
    }

    span.cart-quantity {
      position: absolute;
      background: white;
      height: 18px;
      font-size: 0.7rem;
      font-weight: bold;
      line-height: 18px;
      color: black;
      padding: 0 6px;
      border-radius: 50px;
      text-align: center;
      top: -6px;
      right: -8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    }
  }

  .global-account-btn {
    position: absolute;
    right: 20px;
    top: 40px;
    width: 35px;
    height: 35px;
    padding: 6px;
    background-color: transparent;
  }

  .main-navigation-links {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: $background-color;
    text-align: left !important;
    overflow: hidden;
    transition: height 0.2s ease-in-out;

    li {
      display: block !important;

      a {
        font-size: 1.25rem;
      }

      &:first-child {
        margin-top: 120px;
      }

      &:last-child {
        float: none !important;
      }
    }
  }
}

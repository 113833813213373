.winner-card {
  position: relative;
  background: white;
  border-radius: 13px;
  width: 80%;
  height: 330px;
  margin: 0 auto;
  text-align: center;
  text-decoration: none;
  background-size: cover;
  background-position: 50% 50%;
  box-shadow: 0 1px 5px rgba(0,0,0,0.1607843137254902);
  overflow: hidden;

  a {
    text-decoration: none !important;
  }

  .raffle-details {
    background-color: white;
    padding: 25px 20px;
    position: relative;

    h3 {
      text-align: left;
      position: absolute;
    }

    .raffle-details-tickets {
      margin-top: 55px;
      text-align: left;

      .price {
        display: inline-block;
        background-color: #102964;
        color: white;
        border-radius: 25px;
        opacity: 1;
        padding: 7.5px 15px;
        font-weight: bold;
        letter-spacing: 1px;
        font-size: 0.95rem;
      }
    }
  }

  .winner-details {
    color: white;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;

    &.footer {
      bottom: 0px;
      top: unset;
      transform: unset;

      h1 {
        font-size: 1.2rem;
        font-weight: normal;
      }
    }
    
    p {
      font-size: 1.2rem;
      line-height: 2px;
      letter-spacing: 1px;
      color: white;

      &.winner-title {
        font-weight: bold;
      }
    }
  }
}

.no-winners {
  font-size: 1.2rem;
  font-weight: normal;
  margin: 0 auto;
  text-align: center;
}

.winner-ticket {
  .ticket-number {
    color: white;
    position: relative;
    line-height: 30px;
    letter-spacing: 1px;
  }

  margin: 10px auto 5px auto;
  width: 150px;
  height: 30px;
  background-color: $background-color;
  border-radius: 25px;
}

.winner-name {
  text-align: center;
  width: 100%;
  margin: 15px auto 0 auto;
  text-transform: uppercase;
  letter-spacing: 6.5px;
  font-weight: bold;
  font-size: 22px;
}

.draw-date {
  margin: 10px 0 10px 0;
  letter-spacing: 4px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
}

.winner-prize {
  text-align: center;
  width: 100%;
  margin: 10px auto 5px auto;
  text-transform: uppercase;
  letter-spacing: 5.5px;
  font-weight: bold;
  font-size: 22px;
  padding: 0px 10px 0px 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:hover {
    text-overflow: unset;
    overflow: unset;
    white-space: normal;
  }
}

.winner-image {
  height: 198px;
  width: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: relative;

  .winner-image-shade {
    background: rgba(50, 147, 244, 0.6);
    border-radius: inherit;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}

.winners-text {
  text-align: center;
  margin: 0 auto;
  width: 100%;
  padding: 10px;
  margin-bottom: 50px;
  letter-spacing: 1px;
  line-height: 22px;
}

.verify-draw {
  margin-top: 15px;
  width: 100%;
  text-align: center;
  letter-spacing: 1px;
  font-size: 18px;
  padding-bottom: 20px;
}

.winners-title {
  margin: 0 0 45px 0;
  position: relative;
  letter-spacing: 7.2px;
  font-weight: normal;
  font-size: 1.5rem;
  text-transform: uppercase;
  text-align: center;

  &::after {
    content: "";
    position: absolute;
    margin-left: -155px;
    bottom: -15px;
    width: 157px;
    height: 4px;
    background: transparent linear-gradient(92deg, #f8036d 0%, #ff313d 100%) 0% 0% no-repeat
      padding-box;
    border-radius: 23px;
  }
}

@media screen and (max-width: $mobile-break) {
  .winner-card {
    max-width: 50%;
  }
}

@media screen and (max-width: 1000px) {
  .winner-image {
    background-position-x: 50% !important;
    background-position-y: 30% !important;
  }
}

@media screen and (max-width: 812px) {
  .winner-card {
    max-width: 100%;
  }
}

@media screen and (max-width: 499px) {
  .winner-card {
    max-width: 80%;
  }
}
.admin {
  // position: fixed;
  width: 100%;
  min-width: 1400px;
  height: 100%;
  background: #f4f6fc;
  color: #111;
}

.admin-header {
  width: 100%;
  background: #fafbfd 0% 0% no-repeat padding-box;
  border-bottom: 2px solid #edeef0;
  padding: 30px;

  .header-logo {
    width: 164px;
    height: 49px;
    background: url("/img/pryze-textlogo-black.png") no-repeat;
    background-size: cover;
    background-position: center;
  }
}

.admin-content {
  display: flex;
  flex: 1;
  height: calc(100vh - 111px);
}

.admin-nav {
  width: 17.5%;
  height: 100%;
  background: #fafbfd 0% 0% no-repeat padding-box;
  border-right: 2px solid #edeef0;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  .nav-username {
    background: $background-color;
    padding: 50px 20px;
    color: white;
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  .nav-links {
    margin-top: 20px;

    li a {
      display: block;
      width: 100%;
      text-decoration: none;
      text-align: center;
      font-size: 1.05rem;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 1px;
      padding: 30px 0;

      &:hover,
      &.active {
        background: white;
        color: $background-color;
      }

      svg {
        width: 18px;
        height: 18px;
        vertical-align: top;
        margin-top: -1px;
        margin-right: 5px;
      }
    }
  }
}

.admin-content-main {
  width: 82.5%;
  height: 100%;
  overflow-y: scroll;
  padding: 20px;
}

.title {
  margin: 0 0 45px 0;
  position: relative;
  font-weight: bold;
  font-size: 2.5rem;
  text-align: center;

  &.no-bottom {
    margin-bottom: unset;
  }

  &.low-bottom {
    margin-bottom: 30px;
  }

  &.white {
    color: white;
  }
}

.subtitle {
  &.bottom {
    bottom: 25px;
    font-weight: normal;
  }

  &.secondary {
    font-size: 1.6rem;
  }

  &.top {
    margin-top: 10px;
  }

  &.top-lg {
    margin-bottom: 50px;
    margin-top: 50px;
  }

  position: relative;
  text-align: center;
  font-weight: bold;
  font-size: 1.3rem;
}

.admin-title {
  margin: 0 0 45px 0;
  font-weight: bold;
  font-size: 1.9rem;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.admin-subtitle {
  margin: 0 0 35px 0;
  font-weight: bold;
  font-size: 1.1rem;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

#st-apple-pay {
  a {
    border-radius: 100px;
    height: 37px;
  }

  margin: 10px auto;
  text-align: center;
}

#st-google-pay {
  button {
    border-radius: 100px;
    height: 37px;
    width: 100%;
  }

  margin: 10px auto;
  text-align: center;
}

.payment-form {
  #st-form__submit {
    display: inline-block;
    background: $background-color;
    font-weight: bold;
    color: white;
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 22.5px;
    padding: 7.5px 20px;
    border-radius: 100px;
    border: 0;
    outline: 0;
    width: 100%;
    cursor: pointer;
  }

  .form-input iframe {
    width: 100%;
  }

  .wpwl-button-pay {
    background-color: $background-color;
    border-radius: 30px;
    border-width: 0;
    font-weight: bold;
    margin-top: 10px;
    padding: 12.5px;
    width: 100%;

    &:disabled {
      background-color: $background-color !important;
    }
  }

  .wpwl-control {
    background-color: #f3f8fc;
    border-color: #f3f8fc;
    border-radius: 30px;
    padding: 10px 20px;
    height: 50px;

    &.wpwl-control-cardHolder {
      font-size: 16.25px;
    }

    &.wpwl-control-cardNumber {
      font-size: 15px;
    }

    &.wpwl-control-expiry {
      font-size: 16.25px;
    }
  }

  .wpwl-form.wpwl-form-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0;
    max-width: unset;
  }

  .wpwl-group.wpwl-group-cardNumber {
    flex-basis: 100%;

    .wpwl-label {
      width: 100%;
    }
  }

  .wpwl-group.wpwl-group-cvv {
    flex-basis: 45%;

    .wpwl-label {
      width: 100%;
    }
  }

  .wpwl-group.wpwl-group-expiry {
    flex-basis: 45%;

    .wpwl-label {
      width: 100%;
    }
  }

  .wpwl-group-brand {
    display: none;
  }

  .wpwl-label {
    color: black;
    font-size: 18px;
    margin-bottom: 10px;
    width: 100%;
  }

  .wpwl-wrapper {
    color: black;
    width: 100%;
  }
}

.checkmark {
  width: 50px;
  height: 50px;
  margin: 0 auto;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  margin-bottom: 20px;

  &.error {
    background: #ff313d;
  }

  &.success {
    background: #2fde80;
  }
}

.checkmark svg {
  width: 30px;
  height: 30px;
}

.payment-message {
  text-align: center;

  &.error {
    color: $accent-red !important;
  }
}

.redirect-message {
  display: block;
  margin: 0 auto;
  text-align: center;
}

.processing-header {
  margin-bottom: 20px;
  text-align: center;
}

.select-payment-method {
  .select-card {
    input {
      appearance: none;
    }

    label {
      cursor: pointer;
      margin: auto 0 auto 10px;
    }

    &.selected, &:hover {
      border-color: $background-color;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
    }

    display: flex;
    border: 2px solid rgba(0, 167, 254, 0.25);
    border-radius: 15px;
    padding: 20px;
    margin-bottom: 10px;
    transition: all 0.2s ease-in-out;
  }
}

.payment-form-iframe {
  width: calc(100% + 20px);
  height: 500px;
  margin: -10px;
}

.cart-items-address-title {
  margin: 0 0 30px 0;
  font-weight: bold;
  text-transform: capitalize;
}

.checkout-summary {
  .checkout-summary-header {
    display: flex;
    justify-content: space-between;

    h3 {
      margin-bottom: 25px;
    }

    a.edit-btn {
      color: $background-color;
      font-size: 0.9rem;
      font-weight: bold;
      text-decoration: none;

      svg {
        display: inline-block;
        vertical-align: bottom;
        width: 15px;
        height: 15px;
        margin-right: 2.5px;
      }
    }
  }

  .checkout-summary-totals {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 20px;

    .summary-subtotal,
    .summary-total {
      display: flex;
      justify-content: space-between;
    }

    .summary-subtotal {
      margin-bottom: 20px;

      .summary-amount {
        font-weight: bold;
      }
    }

    .summary-total {
      font-size: 1.4rem;
      font-weight: bold;

      .summary-amount {
        color: #00437f;
      }
    }
  }
}

.or-card {
  &::before, &::after {
    background-color: #BABABA;
    border-radius: 25px;
    content: "";
    margin-top: 8.5px;
    padding: 1px;
    position: absolute;
    width: 35%;
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }

  color: $text-color;
  display: block;
  font-weight: 500;
  letter-spacing: normal;
  margin: 20px 0;
  position: relative;
  text-align: center;
  text-transform: none;
}
.form-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  label {
    &.required::after {
      content: " *";
      color: $accent-red;
    }

    font-size: 1rem;
    margin: 0 0 10px 2px;
    display: block;
    font-weight: bold;
    color: $text-color;
  }
}

.form-image {
  width: 200px;
  height: 100px;
  border-radius: 13px;
  margin-top: 10px;
  background-size: cover;
  background-position: 50% 50%;
}

.input {
  width: 100%;
  background: $input-background-color;
  border: 0;
  outline: 0;
  letter-spacing: 1px;
  font-size: 1rem;
  line-height: 1.5rem;
  color: $text-color;
  padding: 17px 22px;
  border-radius: 10px;

  &:disabled {
    cursor: not-allowed;
  }

  &.quick-pick {
    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    -moz-appearance: textfield;
    max-width: 80px;
    background: unset;
    font-size: 16px;
    font-weight: bold;
    line-height: 16px;
    padding: 0;
    text-align: center;
  }

  &.white {
    background-color: white;
    font-weight: bold;
  }

  &::placeholder {
    color: $text-light-color;
  }
}

.checkbox {
  input,
  label {
    width: auto;
  }

  input {
    margin: auto 0;
  }

  label {
    user-select: none;
    display: inline;
    padding: 0 0 0 7.5px;
    margin: 0;
    font-size: 0.9rem;
    font-weight: bold;
    color: $text-color;
  }
}

.admin-checkbox {
  input,
  label {
    width: auto;
  }

  label {
    user-select: none;
    display: inline-block;
    padding: 0 0 0 7.5px;
    margin: 0;
    font-size: 0.9rem;
  }
}

.form-btn {
  width: 100%;
  padding: 15px 22px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.input-currency {
  position: absolute;
  font-size: 1.1rem;
  opacity: 0.4;
  margin: 11px 15px;
  left: 15px;
}

.admin-input {
  width: 100%;
  background: darken($admin-background-color, 2%);
  border: 0;
  outline: 0;
  letter-spacing: 0.5px;
  font-size: 1rem;
  padding: 10px 16px;
  line-height: 1.5rem;
  color: inherit;
  border-radius: 21px;
  resize: vertical;

  &::placeholder {
    font-size: 0.9rem;
    color: #777;
    text-transform: uppercase;
  }

  &.white {
    background: white;
  }

  &.large {
    font-size: 1.2rem;
    padding: 17px 25px;
    border-radius: 28px;

    &::placeholder {
      font-size: 1.2rem;
      font-weight: bold;
    }
  }

  &.currency {
    padding: 10px 16px 10px 33px;
  }
}

.admin-input-select {
  &.instant {
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='6' fill='white'><polygon points='0,0 12,0 6,6'/></svg>")
    no-repeat 100% $background-color;
    color: white;
    font-weight: bold;
    max-width: 200px;
    padding: 10px 20px;

    option {
      background-color: white;
      color: black;
    }
  }

  appearance: none;
  width: 100%;
  background: $admin-background-color;
  border: 0;
  outline: 0;
  letter-spacing: 1px;
  font-size: 1rem;
  padding: 10px 16px;
  line-height: 1.5rem;
  color: inherit;
  border-radius: 21px;
  resize: vertical;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='6' fill='silver'><polygon points='0,0 12,0 6,6'/></svg>")
    no-repeat 100% $admin-background-color;
  cursor: pointer;
}

.input-dropzone {
  width: 100%;
  height: 108px;
  text-align: center;
  line-height: 100px;
  border-radius: 13px;
  border: 4px dashed #d9d9d9;
  background: #f9f9f9;
  color: #999;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: bold;
  user-select: none;
  outline: none !important;

  &:active,
  &:focus {
    border-color: #08c;
  }

  .text {
    line-height: 100px;
    color: #999;
  }
}

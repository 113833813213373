@media screen and (max-width: $mobile-break) {
  .faqs {
    .faq {
      &.left {
        float: none !important;
      }

      &.right {
        float: none !important;
      }
    }
  }
}

.faqs {
  .faq {
    &.open {
      .faq-question {
        background: $background-color;
        border-radius: 25px 25px 0 0;
        color: white;
      }

      .faq-answer {
        display: block;
        height: auto;
      }
    }

    &.left {
      text-align: left !important;
      float: left;
    }

    &.right {
      text-align: left !important;
      float: right;
    }

    .faq-question {
      background: white 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 25px;
      padding: 20px;
      font-size: 1.2rem;
      cursor: pointer;
      user-select: none;
      font-weight: bold;

      svg {
        vertical-align: middle;
        float: right;
      }
    }

    .faq-answer {
      display: none;
      height: 0;
      background: white 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 0 0 25px 25px;
      padding: 25px 20px;
      color: $text-color;
      line-height: 1.4rem;
    }

    margin: auto;
    width: 85%;
  }
}

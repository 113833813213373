.forgot-password-link,
.login-or {
  font-size: 0.9rem;
  text-transform: uppercase;
  color: $text-light-color;
  font-weight: bold;
  letter-spacing: 1px;
  text-decoration: none;
  transition: 0.2s ease-in-out;
}

.input.disabled {
  color: $text-light-color;
  text-transform: uppercase;
}

.label-code {
  display: inline-block !important;
  margin: 0 0 10px 10px !important;
}

.delete-code {
  position: absolute;
  color: #ff313d;
  margin: 1.5px 0 0 10px;
  cursor: pointer;
}

.login-btn {
  margin-bottom: 20px;
}

.login-or {
  display: block;
  text-align: center;
  text-transform: none;
  letter-spacing: normal;
  margin: 20px 0;
  color: black;
  font-weight: bold;
}

.social-login-btns {
  display: flex;
  justify-content: center;

  button {
    width: 65px;
    height: 65px;
    background-size: 55px 55px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    margin: 0 5px;

    &.facebook-login-btn {
      background-image: url("/img/login-facebook.png");
    }
    &.google-login-btn {
      background-image: url("/img/login-google.png");
    }
    &.apple-login-btn {
      background-image: url("/img/login-apple.png");
    }
  }
}

.register-submit {
  margin-top: 30px;
}

.forgot-password-desc {
  line-height: 1.5rem;
  color: $text-color;
  margin: -10px 0 25px 0;
}

.forgot-password-complete-desc {
  line-height: 1.5rem;
}

.main-panel {
  &.curve {
    border-top-left-radius: 30px;
  }

  background-color: $background-secondary-color;
}

.recaptcha-widget {
  display: flex;
  justify-content: center;
  margin: 25px 0 25px 0;
}

.referral {
  padding: 5px;
  text-align: center;
  margin-bottom: 20px;
  line-height: 25px;
}
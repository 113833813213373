$font-stack: "Manrope", Arial, Helvetica, sans-serif;

$grid-max-width: 1600px;
$grid-inner-max-width: 1300px;
$mobile-break: 1300px;
$gutter-width: 20px;

$background-color: #00A7FE;
$background-secondary-color: #F3F8FC;
$admin-background-color: #f4f6fc;

$accent-red: #e1085f;
$accent-yellow: goldenrod;
$accent-green: #2fde80;

$text-color: #0F2963;
$text-light-color: #707070;
$link-color: #0F2963;
$link-hover-color: #0F2963;

$input-background-color: #F3F8FC;

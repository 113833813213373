.slate {
  background: $background-secondary-color;
  padding: 30px;

  &.no-padding {
    padding: 0;
  }
}

.admin-slate {
  background: white;
  border-radius: 13px;
  padding: 30px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  &.no-padding {
    padding: 0;
  }

  h2 {
    margin: 0 0 20px 0;
    font-size: 1.15rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  p {
    line-height: 1.6rem;
    color: #666;
  }
}

@media screen and (max-width: $mobile-break) {
  .slate {
    padding: 20px;
  }
}

.no-raffles {
  font-size: 1.2rem !important;
  letter-spacing: unset !important;
}

.charities-overview {
  background-color: white;
  border-radius: 25px;
  display: flex;
  justify-content: space-between;
  gap: 20px 20px;
  padding: 20px;

  .donation {
    font-size: 20px;
    font-weight: bolder;
    padding: 50px;
    text-align: center;
    width: 50%;
  }

  .work-for-good {
    background-image: url("/img/workforgood.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-origin: content-box;
    background-position: center;
    cursor: pointer;
    width: 50%;
  }
}

.promoted-raffle-card {
  display: block;
  position: relative;
  background: black;
  border-radius: 13px;
  padding: 20px;
  height: 190px;
  text-align: center;
  text-decoration: none;
  background-size: cover;
  background-position: 50% 50%;
  box-shadow: 0px 3px 6px #00000029;

  &.card-main {
    width: 100%;
    height: 400px;
    border: 1px solid $accent-red;

    h2 {
      font-size: 2rem;
      font-weight: bold;
      letter-spacing: 4px;
      text-transform: uppercase;
    }

    p {
      margin: 20px 0;
      font-size: 1rem;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  }

  &:hover {
    color: inherit;

    .raffle-card-bg-shade {
      background: rgba(0, 0, 0, 0.2);
    }
  }

  .raffle-card-bg-shade {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 12px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    transition: 0.135s ease-in-out;
  }

  .raffle-card-contents {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85%;
  }

  h2 {
    font-size: 1.3rem;
    font-weight: bold;
    letter-spacing: 3px;
    text-transform: uppercase;
  }

  p {
    margin: 15px 0;
    font-size: 0.85rem;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}

.raising-for {
  background: white;
  border-radius: 15px;
  color: #102964;
  padding: 5px 30px;
  text-align: center;
  min-width: 225px;

  .amount {
    font-size: 46px;
    margin: 0 0 5px 0;
  }

  .header {
    font-size: 18px;
    letter-spacing: 0px;
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  .icon {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 20px 0;
    position: relative;
    height: 125px;

    &.gift {
      background-image: url("../../../img/gift.svg");
    }

    &.ribbon {
      background-image: url("../../../img/ribbon.svg");
    }

    &.trophy {
      background-image: url("../../../img/trophy.svg");
    }
  }
}

.raising-money-for {
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  @keyframes slideLogo {
    from {
      transform: translateX(0%);
    }

    to {
      transform: translateX(-100%);
    }
  }

  @keyframes slideLogo2 {
    from {
      transform: translateX(100%);
    }

    to {
      transform: translateX(0%);
    }
  }

  margin: 0 auto;
  width: 85%;

  .brand-slide {
    animation-duration: var(--animation-speed);
    animation-iteration-count: infinite;
    animation-name: slideLogo;
    animation-timing-function: linear;
    display: flex;
    flex-shrink: 0;
    justify-content: space-around;
    min-width: 100%;
    position: absolute;

    &.delay {
      animation-name: slideLogo2;
      transform: translateX(100%);
    }
  }

  .brand-wheel {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 125px;
    overflow: hidden;
    position: relative;

    --animation-speed: 45s;
    --animation-delay: calc(var(--animation-speed) / 2);

    &:hover .brand-slide {
      animation-play-state: paused;
    }

    &::after {
      background-image: linear-gradient(to left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
      content: "";
      height: 100%;
      pointer-events: none;
      position: absolute;
      right: 0;
      width: 5%;
      z-index: 2;
    }

    &::before {
      background-image: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
      content: "";
      height: 100%;
      pointer-events: none;
      position: absolute;
      width: 5%;
      z-index: 2;
    }
  }

  .logo {
    align-self: center;
    animation: fadeIn 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) forwards;
    background-color: white;
    border-radius: 25px;
    display: inline-flex;
    height: 125px;
    margin-right: 10px;
    padding: 0 20px;
    width: 200px;

    .image {
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: contain;
      cursor: pointer;
      height: 90%;
      margin: auto;
      width: 90%;
    }
  }
}

.statistics-text {
  color: white;
  text-align: center;

  &.amount {
    font-size: 64px;
    margin: 0;
  }

  &.header {
    font-size: 24px;
  }
}

.cta-slab {
  padding: 20px 0 0 0;

  &.carousel {
    padding-top: 0;
  }

  &.curve {
    border-top-left-radius: 30px;
  }

  &.double-curve {
    border-radius: 30px 30px 0;
  }

  &.white {
    background-color: white;
  }
}

.steps {
  display: flex;
  justify-content: space-between;
  width: 400px;
}

.stage-box {
  .stage-image {
    background-color: #E8E8E8;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 210px;
    margin: auto;
    border-radius: 5px;
  }

  .info-text {
    text-align: center;
    margin-top: 10px;
    padding: 0 5px;
  }

  background-color: white;
  margin: 0 20px 0 10px;
  max-width: 400px;
  padding: 20px;
  border-radius: 5px;
}

.how-to {
  margin: auto;
}

@media screen and (max-width: $mobile-break) {
  .steps {
    max-width: 300px;
    margin: auto;
  }

  .stage-box {
    margin: auto !important;
  }
}

.cta-slab,
.cta-slate {
  background: $background-secondary-color;

  .cta-image {
    width: calc(100% - 25px);
    height: 350px;
    background-color: black;
    background-size: cover;
    background-position: 50% 40%;
    border-radius: 13px;
  }

  p {
    font-size: 1rem;
    line-height: 1.7rem;
    letter-spacing: 1px;
    color: $text-color;
    margin-bottom: 20px;
  }
}

.category-buttons {
  margin: auto auto 22.5px auto;
  display: flex;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: grey;
    border-radius: 20px;
  }
}

.top-raffles {
  margin: 40px 0 0 0;
}

.testimonials {
  margin-bottom: 40px;
  width: calc(100% + 20px);
}

.ticket {
  margin: auto;
  color: $background-color;
  background-color: white;
  border-radius: 15px;
  font-weight: bold;
  width: 60px;
  height: 30px;
  line-height: 30px;
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.testimonial-slider {
  margin: 0 auto 50px auto;

  .carousel-item {
    padding-right: 5px;
  }

  .carousel-slide {
    outline: 0;
    margin: auto;
  }

  .carousel-dots {
    li button:before {
      color: black;
    }
  }

  .carousel-arrow {
    background: rgba(0, 0, 0, 0.2);
    width: 40px;
    height: 40px;
    top: 50%;
    z-index: 99999;
    position: absolute;
    transform: translateY(-50%);

    &.carousel-prev {
      &::before {
        background-image: url("../../../img/chevron.svg");
        transform: rotate(180deg);
      }

      left: 30px;
    }

    &.carousel-next {
      &::before {
        background-image: url("../../../img/chevron.svg");
        margin-left: 2.5px;
      }

      right: 35px;
    }
  }

  .testimonial {
    position: relative;
    width: 80%;
    max-width: 200px;
    height: 100px;
    background: white;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 40%;
    text-align: center;
    border-radius: 5px;
    //box-shadow: 0px 3px 6px #00000029;
    cursor: default;
    margin: auto;

    &.raffle {
      width: 100%;
      //margin-right: 100px;
    }

    &.winner {
      border-radius: 9px;
      max-width: 500px;
      width: 90%;
      height: 250px;
    }

    .testimonial-image {
      width: 100%;
      height: 100%;
      background-size: 100% auto;
      background-color: #c9c8c8;
      border-radius: 9px;
      z-index: 0;
      background-position: 50% 50%;
    }

    .testimonial-shade {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      background: rgba(50, 147, 244, 0.6);
      z-index: 1;
    }

    .testimonial-inner {
      text-align: center;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 50%);
      width: 100%;
      bottom: 50%;
      z-index: 2;
    }

    p {
      font-size: 1.2rem;
      line-height: 2px;
      letter-spacing: 1px;
      color: white;

      &.testimonial-title {
        font-weight: bold;
      }
    }

    button {
      cursor: default;
    }
  }
}

.how-it-works-video {
  width: 100%;
  padding: 0 30px 0 0;

  iframe {
    border-radius: 13px;
    overflow: hidden;
  }
}

@media screen and (max-width: $mobile-break) {
  .charities-overview {
    .donation {
      width: unset;
    }
    
    .work-for-good {
      height: 100px;
      width: unset;
    }

    display: block;
  }

  .cta-image {
    width: 100% !important;
  }

  .testimonial-slider {
    // .carousel-slide {
    //   width: 100% !important;

    //   &:first-child {
    //     padding-right: 0;
    //     padding-bottom: 10px;
    //   }

    //   &:last-child {
    //     padding-left: 0;
    //   }
    // }
  }

  .how-it-works-video {
    padding: 0;

    iframe {
      height: 250px;
    }
  }
}

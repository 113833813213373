* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
  touch-action: manipulation;
}

html {
  font-size: 15px;
  overflow-y: scroll;
}

body {
  font-family: $font-stack;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $background-color;
  color: $text-color;
}

a {
  color: inherit;
  text-underline-position: under;

  &:hover {
    color: $link-hover-color;
  }
}

p.nl2br {
  white-space: pre-line;
}

.left {
  text-align: left !important;
}

.right {
  text-align: right !important;
}

.margin-center {
  margin: auto;
}

.center {
  text-align: center !important;
}

.inline-block {
  display: inline-block !important;
  // vertical-align: middle;
}

.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tag {
  display: inline-block;
  color: white;
  font-size: 0.9rem;
  font-weight: bold;
  text-transform: uppercase;
  background: $background-color;
  padding: 5px 10px;
  border-radius: 50px;

  &.clickable {
    &:hover {
      color: white;
    }

    cursor: pointer;
    text-decoration: none;
  }

  &.no-border {
    border-color: transparent;
  }

  svg {
    vertical-align: middle;
  }
}

.text-green {
  color: $accent-green;
}

.text-red {
  color: $accent-red;
}

.text-muted {
  color: #777;
}

.tooltip {
  background: black !important;
  text-transform: none;
  letter-spacing: 0.5px;
  font-size: 13px !important;
  max-width: 400px;
  text-align: center;

  &:after {
    border-top-color: black !important;
  }
}
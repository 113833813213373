.btn {
  display: inline-block;
  background-color: white;
  border: 2px white solid !important;
  color: $background-color !important;
  font-size: 1rem;
  text-align: center;
  text-decoration: none;
  line-height: 1.5rem !important;
  padding: 5px 20px;
  border-radius: 50px;
  border: 0;
  outline: 0;
  cursor: pointer;

  svg {
    width: 18px;
    height: 18px;
    vertical-align: top;
    margin-top: 2px;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &.btn-transparent {
    background: transparent;
    border: 2px white solid;
    border-radius: 30px;
    color: white !important;
    margin-right: 20px;
  }

  &.btn-white {
    background: white;
    border: 1px $background-color solid;
    text-transform: none;
    letter-spacing: normal;
    font-weight: bold;
    border-radius: 30px;
  }

  &.btn-black {
    background: black;
    border: 2px solid $accent-red;
    color: $accent-red;
    font-weight: bold;

    &:hover {
      color: $accent-red !important;
    }
  }

  &.btn-blue {
    &.live {
      .live-dot {
        width: 13px;
        height: 13px;
        background: $accent-red;
        border-radius: 100%;
        margin: auto 10px auto 0;
      }

      display: flex;
      border-color: transparent !important;
      font-weight: normal;
      margin: auto;
      padding: 10px 20px;
      width: fit-content;
    }

    &.full {
      display: block;
      font-size: 18px;
      margin: 0 auto 10px;
      padding: 10px;
      width: 90%;

      @media screen and (max-width: $mobile-break) {
        width: 100%;
      }
    }

    background: $background-color;
    color: white !important;
    font-weight: bold;
    text-transform: none;
    letter-spacing: normal;
    border-radius: 30px;
  }

  &.btn-darkblue {
    background: #007DB4;
    color: white !important;
    font-weight: bold;
    text-transform: none;
    letter-spacing: normal;
    border-radius: 15px;
  }

  &.btn-large {
    padding: 12.5px 35px;
    font-size: 1.15rem;
  }

  &.btn-small {
    padding: 4px 18px;
    font-size: 0.85rem;
    letter-spacing: 1px;
  }

  &.btn-tiny {
    padding: 1px 10px;
    font-size: 0.8rem !important;
  }

  &.btn-admin {
    background: $background-color;
    border: unset !important;
    color: white !important;
    font-size: 0.9rem;
    font-weight: bold;

    &.btn-paginate {
      width: 45px;
      padding: 10.2px 7px;

      svg {
        vertical-align: middle;
        width: 25px;
        height: 25px;
        margin-top: -0.5px;
      }
    }
  }

  &.bottom {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  &.circle {
    width: 65px;
    height: 65px;
    line-height: 0 !important;
    padding: 25px;
    border-radius: 50px;
    font-size: 1.5rem;
  }

  &.margin-bottom {
    margin-bottom: 7.5px;
  }

  &.no-outline {
    border: none;
  }

  &.padded {
    padding: 7.5px 30px;
  }

  &.round {
    border-radius: 50px;
  }

  &.spaced {
    margin-right: 10px;
  }
}

button {
  &.selected {
    background: black;
    color: white;
  }
}

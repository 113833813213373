.config-section {
  margin-bottom: 80px;
}

.promo-popup-recommended-size {
  display: block;
  margin-top: 20px;
  font-size: 0.9rem;
  color: #999;
  letter-spacing: 0.5px;
}

.config-landing-page {
  .landing-option {
    display: flex;
    margin-bottom: 25px;

    input {
      margin: auto 10px auto 0;
    }

    label {
      font-weight: normal;
      margin: unset;
    }
  }
  
  small {
    margin-left: 10px;
  }
}

.config-promo-popup {
  .promo-option {
    margin-bottom: 25px;

    input {
      margin-right: 10px;
    }
  }

  small {
    margin-left: 20px;
    color: #999;
    letter-spacing: 0.5px;
  }
}

.config-date {
  .admin-input {
    margin-bottom: 10px;
  }

  button {
    margin-right: 10px;
  }
}

.config-referral-scheme {
  button {
    margin-right: 10px;
  }

  input {
    margin-bottom: 5px;
  }
}

.config-dynamic-text {
  position: relative;

  textarea {
    padding: 15px 15px 60px 15px;
  }

  .dynamic-text-actions {
    position: absolute;
    bottom: 4px;
    left: 0;
    background: rgba(255, 255, 255, 0.9);
    padding: 15px 20px;
    width: 100%;
    border-radius: 0 0 21px 21px;
    box-shadow: 0 0 10px white;

    button {
      box-shadow: 0 0 50px rgba(0, 0, 0, 0.45);
    }

    small {
      margin-left: 20px;
      color: #999;
      letter-spacing: 0.5px;
    }
  }
}

.config-dynamic-image {
  position: relative;
  width: 100%;
  height: 130px;
  background: black;
  color: white;
  border-radius: 13px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  background-size: cover;
  background-position: 50% 50%;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    border-radius: inherit;
  }

  &.large {
    width: 500px;
    height: 220px;
  }

  .edit-icon {
    position: absolute;
    bottom: 15px;
    right: 20px;
  }
}

.config-promoted-raffle {
  position: relative;
  display: inline-block;
  margin: 0 20px 20px 0;
  width: 300px;
  height: 180px;
  background: grey;
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: white;
  border-radius: 13px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  background-size: cover;
  background-position: 50% 50%;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    border-radius: inherit;
  }

  &.large {
    width: 500px;
    height: 220px;
  }

  .edit-icon {
    position: absolute;
    bottom: 15px;
    right: 20px;
  }

  .inner {
    position: absolute;
    width: 70%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    small {
      display: block;
      font-size: 0.8rem;
      margin-bottom: 10px;
      opacity: 0.8;
    }
  }
}

.config-table {
  width: 100%;
  letter-spacing: 0.5px;
  background: white;
  border-radius: 13px;

  &.no-side-padding {
    tr td {
      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  thead {
    th {
      color: #aaa;
      padding: 10px;
      border-bottom: 1px solid #eee;
    }
  }

  tbody {
    .status-dot {
      width: 13px;
      height: 13px;
      background: black;
      border-radius: 100%;

      &.active {
        background: $accent-green;
      }

      &.inactive {
        background: $accent-red;
      }
    }
    
    tr:last-child td {
      border: 0;
    }

    td {
      text-align: center;
      padding: 5px 10px;
      border-bottom: 1px solid #eee;
      line-height: 22px;
      max-width: 425px;
      word-wrap: break-word;

      &.no-raffles {
        padding: 20px;
      }

      .delete-btn {
        display: inline-block;
        vertical-align: middle;
        background: none;
        border: 0;
        outline: 0;
        margin: 5px 0px 5px 15px;
        color: $background-color;
        cursor: pointer;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
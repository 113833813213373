.modal-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding: 50px;
}

.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999;
}

.modal {
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  background: white;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  border-radius: 13px;
  padding: 30px;
  width: 600px;
  z-index: 999999;

  h2 {
    margin: 0 0 20px 0;
    font-size: 1.15rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  h3 {
    margin: 0 0 20px 0;
    font-size: 0.95rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}

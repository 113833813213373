.background-logo {
  background-image: url("../../../img/pryze-logo-full.png");
  background-position: 0% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  opacity: 0.15;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.contact-form.subscribe {
  width: 550px;

  .btn-white.subscribe {
    display: block;
    color: $text-color !important;
    margin: 0 auto;
    padding: 5px 15px !important;
    width: unset;
  }

  .input {
    font-weight: normal;
  }

  label {
    color: white;
    display: block;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  .contact-form.subscribe {
    width: 100%;
  }
}

.landing-logo {
  background-image: url("../../../img/pryze-textlogo.png");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 200px;
  margin: 0 auto;
  width: 275px;
}

.landing-text {
  color: white;
  text-align: center;

  h1 {
    font-family: 'Quicksand', 'Manrope', Arial, Helvetica, sans-serif;
    font-size: 72px;
  }

  p {
    font-size: 22px;
  }
}
.contribution {
  background-color: $background-secondary-color;
  border-radius: 25px;
  margin-bottom: 20px;
  padding: 60px;
  position: relative;

  &.helped {
    //padding: 30px 20px;
    width: 31%;
  }
}

.contribution-inner {
  display: flex;
  grid-gap: 40px;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.contribution-amount {
  &.helped {
    font-size: 28px !important;
  }

  font-size: 32px !important;
  font-weight: bold;
  margin: auto;
  margin-bottom: auto !important;
  width: fit-content;
}

.contribution-logo {
  background-position: 40% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  height: 75px;
  width: 95px;
}

.contribution-text {
  &.comma {
    min-width: 175px;
  }

  font-weight: bold;
  min-width: 225px;
  margin-bottom: 0 !important;
  text-align: center;
  text-overflow: ellipsis;
}

.user-account {
  background-color: white;
  border-radius: 30px;
  margin: auto;
  max-width: 1070px;
}

.user-account-name {
  background-color: #00437f;
  display: inline-block;
  color: white;
  padding: 30px;
  font-size: 1.5rem;
  font-weight: bold;
  border-radius: 30px 0 15px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.user-account-promobal {
  position: relative;
  width: fit-content;
  margin: 5px 0 0 -5px;
  border-radius: 30px;
  padding: 3px 0 6.5px;
  background-color: white;
  color: #00437f;
  overflow: hidden;

  .promobal-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    margin: 0 5px 0 10px;
  }

  .promobal-label {
    position: relative;
    margin: 5px 0 0 35px;
    font-size: 1rem;
    font-weight: bold;
    padding-right: 10px;
  }

  .promobal-amount {
    position: relative;
    font-size: 1rem;
    margin-top: 5px;
    font-weight: bold;
    z-index: 99;
  }

  .promobal-history {
    display: inline-block;
    background: none;
    border: 0;
    outline: 0;
    cursor: pointer;
    color: white;
    margin-left: 10px;

    svg {
      width: 17px;
      height: 17px;
    }
  }

  .promobal-add {
    position: absolute;
    top: 15px;
    right: 15px;
    background: rgba(255, 255, 255, 0.4);
    border: 0;
    line-height: 25px;
    height: 25px;
    width: 25px;
    border-radius: 100%;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
    transition: 0.2s;

    &.close {
      transform: rotate(45deg);
    }
  }

  .promobal-code-form {
    margin-top: 12px;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    padding-top: 15px;

    label {
      color: white;
    }

    input {
      position: relative;
      font-size: 0.9rem;
      padding: 9px 15px;
      background: white;
      color: #333;
      letter-spacing: 0.5px;
    }
  }
}

.account-text {
  &.admin-text {
    max-width: 1000px;
  }

  max-width: 850px;
}

.user-account-referrals {
  background: $background-secondary-color;
  border-radius: 25px;
  padding: 25px 20px;

  &.details {
    max-height: 600px;
  }

  .link-box {
    .code {
      font-size: 32px;
      font-weight: bold;
      width: fit-content;
    }
    
    .link {
      display: block;
      font-size: 12px;
      margin-top: 10px;
    }

    cursor: pointer;
    border-radius: 7.5px;
    width: fit-content;
  }

  h2 {
    margin: 0;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  table {
    border-collapse: collapse;
    width: 100%;

    th {
      padding: 0 20px;
      text-align: left;
    }

    td {
      &.complete {
        color: $background-color;
        font-weight: bold;
      }

      &.invite {
        a {
          &:hover {
            color: unset;
          }
          
          text-decoration: none;
        }

        color: $background-color;
      }

      padding: 20px 20px 0 20px;
    }
  }
}

.user-account-nav {
  display: inline-block;
  padding: 45px 0;

  ul li {
    display: inline;
    position: relative;
    margin-bottom: 30px;

    &:last-child {
      margin: 0;
    }

    a {
      font-size: 1.15rem;
      font-weight: bold;
      padding-left: 30px;
      text-decoration: none;

      &.under:hover::after {
        transform: scaleX(1);
        transition: transform .25s ease-in-out;
      }

      &.under::after {
        border-bottom: 2px solid #00437f;
        margin-left: 30px;
        bottom: -10px;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        transform: scaleX(0);
        transform-origin: 50% 50%;
      }

      &.active::after {
        transform: scaleX(1);
      }

      &.admin-link {
        color: $accent-red;
        font-weight: bold;
      }
    }
  }
}

.user-account-main-header {
  margin-bottom: 20px;

  .back-btn {
    display: inline-block;
    vertical-align: top;
    margin: 5px 20px 0 0;
    opacity: 0.5;
  }

  h1 {
    margin: 0;
    line-height: 2.5rem;
    font-size: 1.5rem;
    font-weight: bold;
  }

  // switcher element, kinda like iOS toggles
  .btn-switcher {
    display: inline-block;
    position: relative;
    padding: 5px;
    background: $background-secondary-color;
    border-radius: 23px;
    transition: 0.2s ease-in-out;

    &.account-history-sorter {
      &.active-one:before {
        width: 76px;
      }
      &.active-two:before {
        width: 86px;
        left: 83px;
      }
    }

    &.tickets-sorter {
      &.active-one:before {
        width: 140px;
      }
      
      &.active-two:before {
        width: 115px;
        left: 153px;
      }
    }

    &.active-one:before {
      left: 8px;
      width: 50%;
      transition: 0.2s;
    }

    &.active-two:before {
      left: calc(50% - 8px);
      width: 50%;
      transition: 0.2s;
    }

    &:before {
      top: 5px;
      border-radius: 30px;
      content: "";
      background: $background-color;
      height: 27px;
      display: block;
      position: absolute;
    }

    button {
      color: $text-color;
      font-weight: bold;
      border-radius: 23px;
      display: inline-block;
      padding: 7px 12px;
      margin-right: 0px;
      text-decoration: none;
      font-size: 0.8rem;
      text-transform: uppercase;
      letter-spacing: 1px;
      position: relative;
      background: none;
      border: 0;
      outline: 0;
      cursor: pointer;

      &.active {
        color: white;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.referral-log {
  margin-bottom: 0 !important;
}

.edit-details-subtitle {
  margin: 20px 0 30px 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bold;
}

.row.no-margin.edit-details {
  padding: 5px;
}

.edit-details {
  margin-bottom: 30px;

  &.upper {
    color: grey;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  input {
    margin: auto 0;
  }

  label {
    margin-left: 10px;
  }
}

.account-settings {
  .settings-link {
    display: flex;
    width: 100%;
    text-decoration: none;
    background: $background-secondary-color;
    margin-bottom: 15px;
    padding: 20px;
    border-radius: 10px;
    transition: 0.05s;
    color: $text-color;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      background: darken($background-secondary-color, 3%);
    }

    svg,
    span {
      display: inline-block;
    }

    svg {
      flex-shrink: 0;
      width: 25px;
      height: 25px;
      margin: 5px 20px 5px 5px;
    }

    span {
      h4 {
        margin-bottom: 5px;
        text-transform: uppercase;
        letter-spacing: 1px;

        .link-tag {
          font-size: 0.6rem;
          padding: 3px 8px;
          margin-left: 10px;
          display: inline-block;
          vertical-align: top;
          border-radius: 100px;
          color: white;

          &.on {
            background: $accent-green;
          }

          &.off {
            background: $accent-red;
          }
        }
      }

      p {
        color: $text-light-color;
        font-size: 0.9rem;
      }
    }
  }
}

.two-fac {
  .qrcode-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    .qrcode-img img {
      width: 180px;
      height: 180px;
    }

    .qrcode-text {
      background: $background-secondary-color;
      padding: 15px;
      width: 180px;
      height: 180px;
      margin-left: 20px;
      border-radius: 30px;
      color: #ddd;

      .fb-code {
        font-family: monospace;
        width: 100px;
        margin: 10px auto 0 auto;
        font-weight: bold;
      }
    }
  }
}

.tfa-login .pincode-input-container {
  margin: 20px 0;
  text-align: center;

  .pincode-input-text {
    background-color: white !important;
  }
}

.pincode-input-container {
  margin: 20px 0;
  text-align: center;

  .pincode-input-text {
    background: $background-secondary-color !important;
    border: 0 !important;
    border-radius: 10px;
    font-size: 2rem !important;
    color: $text-color;
    font-weight: bold;
    width: 60px !important;
    height: 60px !important;
    margin: 5px !important;
  }
}

.promotional-balance-history-table {
  width: 100%;
  border-collapse: collapse;

  tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    th {
      text-transform: uppercase;
      font-size: 0.85rem;
      letter-spacing: 2px;
      color: $background-color;
    }

    td,
    th {
      text-align: left;
      padding: 20px 10px;

      .trans-positive {
        font-weight: bold;

        &:before {
          content: "+";
        }
      }

      .trans-negative {
        font-weight: bold;
      }
    }
  }
}

.order-item-container {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.order-item {
  display: flex;
  justify-content: space-between;
  background: $background-secondary-color;
  border-radius: 10px;
  padding: 20px;
  transition: 0.15s ease-in-out;

  &.expanded {
    border-radius: 10px 10px 0 0;
  }

  .order-item-details {
    display: flex;
    flex-wrap: wrap;
    flex: 0.5;
    justify-content: space-between;

    .order-item-detail {
      .detail-header {
        font-size: 0.75rem;
        font-weight: bold;
        color: $background-color;
        text-transform: uppercase;
        letter-spacing: 2px;
      }

      .detail-body {
        margin-top: 5px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: bold;
      }
    }
  }

  .order-item-status-total {
    display: flex;
    flex: 0.5;
    flex-wrap: wrap;
    align-items: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    justify-content: flex-end;

    .order-item-total {
      margin-left: 15px;
      font-weight: bold;
    }

    .order-item-expcoll {
      flex-wrap: wrap;
      margin-left: 15px;
      background: none;
      color: $text-color;
      outline: 0;
      border: 0;
      cursor: pointer;
    }
  }
}

.order-item-expanded-content {
  border-radius: 0 0 10px 10px;
  width: 100%;
  background: darken($background-secondary-color, 3%);
  z-index: 1;
  transition: 0.2s ease-in-out;
  overflow: hidden;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 0.9rem;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: grey;
    border-radius: 20px;
  }

  .order-item-tickets {
    margin: 20px;

    .order-item-ticket {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      .text-muted {
        margin: 0 10px;
      }

      &:last-child {
        margin: 0;
      }
    }
  }

  .order-payment {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 20px;
  }

  .order-card-details {
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
    }
  }

  .order-breakdown {
    .breakdown-item {
      text-align: right;
      margin-bottom: 10px;

      &:last-child {
        margin: 0;
      }

      .breakdown-item-text {
        color: $background-color;
        font-size: 0.8rem;
        font-weight: bold;
        margin-bottom: 5px;
      }

      .breakdown-item-amount {
        font-size: 1rem;
        font-weight: bold;
      }
    }
  }
}

@media screen and (max-width: $mobile-break) {
  .account-settings .settings-link span h4 .link-tag {
    margin: 0;
  }

  .contribution-inner.overview {
    display: block;

    .contribution-text.main {
      margin-top: 20px;
    }
  }

  .user-account-main-header {
    display: block;

    .col {
      display: block;
      text-align: center !important;
    }
  }

  .user-account {
    width: 100%;

    .user-account-name {
      border-radius: 30px 30px 0 0;
      width: 100%;
    }

    .user-account-nav {
      padding: 22.5px 0;

      ul li {
        display: block;
        margin-bottom: 15px;

        &.admin-link {
          margin-bottom: 30px;
        }
  
        a {
          &.under {
            transition: border-left 0.2s ease-in-out;
  
            &.active {
              &::after {
                display: none;
              }
  
              border-left: 5px solid #00437f;
              padding-left: 25px;
            }

            &::after {
              display: none;
            }

            &:hover {
              border-left: 5px solid #00437f;
              padding-left: 25px;
            }
          }
        }
      }
    }
  }

  .order-item-details > div {
    margin: 5px 0;
  }

  .order-item-status-total > div {
    margin: 5px 0;
    text-align: right;
  }

  .pincode-input-container .pincode-input-text {
    // background: $background-color !important;
    // border: 0 !important;
    // border-radius: 10px;
    font-size: 1.5rem !important;
    color: $text-color !important;
    width: 55px !important;
    height: 55px !important;
    margin: 2px !important;
  }
}

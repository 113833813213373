.contact-form {
  margin: 0 auto;
  width: 450px;

  .btn {
    margin-top: 20px;
    padding: 15px 25px;
    width: 100%;
  }

  input {
    margin-bottom: 20px;
  }

  textarea {
    margin-bottom: 20px;
    max-width: 100%;
    min-height: 200px;
    min-width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .contact-form {
    width: unset;
  }
}
.md-text {
  &.white {
    color: white;
  }

  ol,
  ul,
  li {
    list-style-type: decimal !important;
  }

  ol {
    padding: 0 25px;
  }

  ol li {
    padding-left: 0px;
  }

  p,
  ol,
  li,
  ul {
    line-height: 1.8rem;
    margin-bottom: 20px;
    color: inherit;
    white-space: pre-wrap;
  }
}

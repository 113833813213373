.sort-dropdown-container {
  position: relative;

  .sort-dropdown {
    position: absolute;
    top: 50px;
    right: 0;
    width: 200px;
    background: white;
    color: #222;
    border-radius: 13px;
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.2);
    z-index: 1;

    &:before {
      content: "";
      position: absolute;
      top: -9px;
      right: 20px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 9px 9px 9px;
      border-color: transparent transparent white transparent;
    }

    li {
      display: block;
      width: 100%;
      padding: 15px 15px;
      border-bottom: 1px solid #ddd;
      font-size: 0.9rem;
      text-transform: uppercase;
      letter-spacing: 1px;
      cursor: pointer;

      svg {
        width: 14px;
        height: 14px;
        position: absolute;
        vertical-align: top;
        left: 10px;
        stroke-width: 3px;
        color: $accent-green;
      }

      &:hover {
        background: #eee;

        &:first-child {
          border-radius: 13px 13px 0 0;
        }
        &:last-child {
          border-radius: 0 0 13px 13px;
        }
      }

      &:last-child {
        border: 0;
      }
    }
  }
}

.draw-time {
  color: white;
  font-size: 20px;
  text-align: center;
  padding: 0 10px;
}

.raffle-slider {
  .raffle-carousel-slide {
    outline: 0;
    margin: auto;
  }

  .slick-arrow {
    background: transparent;
    width: 30px;
    top: 50%;
    z-index: 1;
    position: absolute;
    transform: translateY(-50%);
    transition: 0.25s ease-in-out;

    &.slick-prev {
      &::before {
        color: rgba(0, 0, 0, 0.5);
        transform: rotate(180deg);
      }

      left: 0;
    }

    &.slick-next {
      &::before {
        color: rgba(0, 0, 0, 0.5);
        margin-left: 2.5px;
      }

      right: 0;
    }
  }

  .slick-list {
    line-height: 0;
  }

  /*
  .slick-track {
    height: 400px;
  }
  */

  .raffle-image {
    position: relative;
    width: 100%;
    //height: 400px;
    height: 100%;
    background-color: #c9c8c8;
    background-size: cover;
    background-position: 50% 50%;
    text-align: center;
    //border-radius: 5px;
    //box-shadow: 0px 3px 6px #00000029;
    cursor: default;
    margin: auto;

    &.curve {
      border-top-left-radius: 30px;
    }

    &.raffle {
      width: 100%;
      //margin-right: 100px;
    }

    &.winner {
      width: 80%;
      height: 250px;
    }

    .testimonial-image {
      width: 100%;
      height: 70%;
      background-color: #c9c8c8;
    }

    .testimonial-shade {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      background: rgba(0, 0, 0, 0.4);
    }

    .testimonial-inner {
      width: 80%;
      margin: auto;
    }

    p {
      font-size: 1.2rem;
      line-height: 2px;
      letter-spacing: 1px;
      color: $text-color;

      &.testimonial-title {
        font-weight: bold;
        margin-top: 30px;
      }
    }

    button {
      cursor: default;
    }
  }
}

.raffle-card {
  display: block;
  position: relative;
  background-color: white;
  border-radius: 13px;
  height: 330px;
  width: 80%;
  margin: auto;
  text-decoration: none;
  background-size: cover;
  background-position: 50% 50%;
  box-shadow: 0px 1px 5px #00000029;
  overflow: hidden;

  &.also-interested {
    width: 90%;
  }

  .raffle-card-image {
    width: 100%;
    height: 60%;
    background-color: $background-color;
    background-position: 50% 50%;
    background-size: cover;
  }

  .raffle-sale-banner {
    background-color: white;
    color: #E1085F;
    position: absolute;
    z-index: 0;
    margin: 10px 0 0 10px;
    border-radius: 50px;
    padding: 5px 10px;
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 5px;

    .sale-text {
      color: $accent-red;
      font-size: 22px;
      margin: 0;
    }
  }

  /*
  &:hover {
    color: inherit;

    .raffle-card-bg-shade {
      background: rgba(0, 0, 0, 0.2);
      transition: 0.5s;
    }
  }
  */

  .raffle-card-contents {
    display: flex;
    justify-content: space-between;
    padding: 2.5px 15px 0;

    .raffle-title {
      font-size: 1.2rem;
      letter-spacing: normal;
      font-weight: bold;
      margin: 10px 10px 15px 0;
      max-width: 85%;
    }

    .time-left {
      display: flex;
      margin: auto 0;

      svg {
        color: #102964;
        margin: auto 5px auto 0;
      }
    }
  }

  .price {
    display: inline-block;
    background-color: #102964;
    color: white;
    border-radius: 25px;
    opacity: 1;
    padding: 7.5px 15px;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 0.95rem;
    bottom: 25px;
    position: absolute;
  }
}

.raffle-info {
  position: relative;
  
  .raffle-sale-banner {
    background-color: white;
    color: #E1085F;
    position: absolute;
    margin: 25px 0 0 25px;
    top: 0;
    border-radius: 50px;
    padding: 25px;
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 5px;

    .sale-text {
      color: $accent-red;
      font-size: 42px;
      margin: 0;
    }
  }

  .image-slider-container,
  .image-livestream {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 250px;
    background: black;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 13px;
    background-size: cover;
    background-position: 50% 50%;

    button {
      position: absolute;
      width: 40px;
      height: 40px;
      border: 0;
      border-radius: 100%;
      background: rgba(0, 0, 0, 0.5);
      color: white;
      cursor: pointer;
      outline: 0;

      svg {
        margin: 5px auto;
        width: 30px;
        height: 30px;
      }

      &:disabled {
        background: rgba(0, 0, 0, 0.15);
        color: rgba(255, 255, 255, 0.5);
        cursor: not-allowed;
      }
    }

    button.prev {
      bottom: 20px;
      right: 20px;
    }
    button.next {
      bottom: 20px;
      right: 70px;
    }
  }

  .raffle-image.has-info-box {
    .image-slider-container,
    .image-livestream {
      height: 390px;
    }
  }

  .raffle-specifics {
    padding: 10px 30px;
    position: relative;

    .live-draw-section {
      position: relative;
    }

    .live-draw {
      .circle {
        background-color: $accent-red;
        border-radius: 50%;
        height: 10px;
        margin: auto 5px auto 0;
        padding: 5px;
      }

      background-color: white;
      border-radius: 50px;
      color: $text-color;
      display: flex;
      font-weight: bold;
      left: 17.5px;
      padding: 5px 10px;
      position: absolute;
      text-transform: uppercase;
      top: 12.5px;
      z-index: 1;
    }

    .support-and-win {
      display: inline-block;

      .support {
        color: $background-color;
      }

      .win {
        color: #102964;
        font-weight: bold;
        font-size: 2.5rem;
      }
    }

    .tickets-overview {
      .CircularProgressbar-path {
        stroke: #102964;
        stroke-linecap: round;
        stroke-width: 8px;
        transition-duration: 0.5s;
      }

      .CircularProgressbar-trail {
        stroke: #F3F8FC;
        stroke-linecap: round;
      }

      .progress-bar {
        width: 100%;
        display: inline-block;
      }

      .tickets {
        text-align: center;
        letter-spacing: unset;

        .sold {
          color: #0E255C;
          font-size: 16px;
          font-weight: bold;
          margin-top: 10px;
          line-height: 18px;
          margin-bottom: 0;
        }

        .total {
          color: #C3CFD8;
          font-size: 14px;
          font-weight: bold;
        }
      }

      .ticket-stats {
        p {
          &.first {
            margin-top: 10px;
          }

          color: #0E255C;
          font-weight: bold;
          margin-bottom: 0;
        }
      }

      .percentage-sales {
        .charities {
          .charity-image {
            background-image: url("/img/workforgood.png");
            background-repeat: no-repeat;
            background-size: contain;
            background-origin: content-box;
            background-position: center;
            cursor: pointer;
            margin: auto;
            height: 75px;
          }

          .charity-name {
            display: block;
            font-weight: bold;
            font-size: 24px;
            margin-bottom: 10px;
            margin-top: 10px;
          }

          .donation {
            display: block;
            font-size: 18px;
          }

          .work-for-good {
            display: block;
          }

          margin-top: 20px;
        }

        padding: 10px 30px;
        font-size: 20px;
        text-align: center;
      }
      
      background-color: white;
      border-radius: 25px;
      min-height: 220px;
      position: relative;
    }

    h1 {
      margin-top: 0px;
      font-size: 1.5rem;
      font-weight: normal;
    }

    .price-btn {
      color: white !important;
      background-color: $background-color;
      font-size: 1.3rem;
      padding: 10px 20px 10px 125px;
      position: relative;
      font-weight: bold;
      text-align: center;
      margin-top: 10px;
      cursor: pointer;

      &.sale {
        padding: 0 20px 0 125px;
      }

      small {
        font-size: 1rem;
      }

      .quantity {
        &.sale {
          top: unset;
          left: 17px;
          bottom: 50%;
          transform: translateY(50%);
        }

        background: white;
        color: #222;
        position: absolute;
        left: 7px;
        top: 6px;
        width: 105px;
        height: 30px;
        border-radius: 100px;
        line-height: 30px;
        font-size: 1rem;
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    .live-draw-countdown {
      margin-top: 30px;

      h3 {
        font-size: 1rem;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: normal;
      }

      .countdown-numbers {
        margin-top: 15px;
        margin-left: -10px;

        .countdown-number {
          display: inline-block;
          width: 70px;
          font-size: 2.5rem;
          font-weight: bold;
          text-align: center;
          color: $accent-red;

          span {
            display: block;
            font-size: 0.7rem;
            font-weight: normal;
            color: white;
            margin-top: 5px;
            text-transform: uppercase;
            letter-spacing: 1px;
          }
        }
      }
    }

    .tickets-remaining {
      margin-top: 30px;

      h3 {
        font-size: 1rem;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: normal;
      }
    }

    .guaranteed-winner-info-box {
      width: fit-content;
      margin-top: 20px;
      padding: 3px 12px;
      border-radius: 100px;
      background: rgb(246, 208, 76);
      color: #1b1c31;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      font-size: 0.8rem;
      line-height: 20px;
      font-weight: bold;
      letter-spacing: 0px;

      svg {
        width: 15px;
        height: 15px;
        margin-left: 10px;
      }
    }
  }
}

.tickets-bar-container {
  width: 64%;
  position: absolute;
  right: 15px;
  bottom: 20px;

  .bar {
    position: relative;
    width: 100%;
    height: 10px;
    background-color: $background-secondary-color;
    border-radius: 50px;
    margin: 15px 0 10px 0;
    overflow: hidden;

    .bar-fill {
      position: absolute;
      top: 0;
      left: 0;
      width: 0%;
      height: 10px;
      background: transparent linear-gradient(90deg, #02B4FE 0%, #017FFE 100%) 0% 0% no-repeat padding-box;
      border-radius: 50px;
      animation: soldTickets ease 1s normal both;
    }
  }

  .bar-indices {
    display: flex;
    justify-content: space-between;

    div {
      font-size: 0.9rem;
      font-weight: bold;

      &.tickets-total {
        opacity: 0.5;
      }
    }
  }
}

@keyframes soldTickets {
  0%   {width: 0}
  100% {width:var(--width);}
}

div.free-entry {
  padding-bottom: 10px;
  text-align: center;
}

span.free-entry {
  color: #102964;
  margin: auto;

  a {
    font-weight: bold;
  }
}

.raffle-description {
  p {
    font-size: 1.1rem;
    line-height: 1.6rem;
  }

  &.no-bottom {
    margin-bottom: unset;
  }

  &.no-top {
    margin-top: unset;
  }

  margin-top: 30px;
  margin-bottom: 40px;
  max-width: $grid-inner-max-width;
}

.selector-btn {
  z-index: -1;
  width: 65px;
  height: 65px;
  margin: 5px;
  background-color: $background-secondary-color;
  border-radius: 65px;
  border: 0;
  outline: 0;
  color: $text-color;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  user-select: none;

  &.letter {
    height: 45px;
    width: 85px;
  }

  &.selected {
    background-color: $background-color;
    color: white;
  }

  &.overview {
    width: 45px;
    height: 45px;
    border-radius: 45px;
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
}

.instant-wins-prizes {
  h2 {
    text-align: center;
  }

  &.header {
    background-color: $background-color;
    border-radius: 35px 35px 0 0;
    color: white;
    padding: 2.5px 50px;

    @media screen and (max-width: $mobile-break) {
      .header {
        text-align: center;
      }
    }
  }

  &.body {
    padding: 2.5px 50px;

    @media screen and (max-width: $mobile-break) {
      padding: unset;
    }
  }

  background-color: white;
  border-radius: 0 0 35px 35px;
  color: $text-color;
  margin: 0 auto;
  max-width: calc($grid-inner-max-width - 40px);
  overflow: hidden;
  transition: all 0.25s ease-in-out;
  width: 100%;
}

.instant-wins-table {
  .record {
    &.bg {
      &.order {
        min-height: 75px;
        padding: 5px 0;

        @media screen and (max-width: $mobile-break) {
          border-radius: 20px 20px 0 0;
        }
      }

      &.padded {
        padding: 25px 0;
      }
      
      &.reveal {
        background-color: white;
        min-height: 75px;
        padding: 5px 0;
        
        @media screen and (max-width: 725px) {
          padding: 20px 5px;
        }
      }
      
      background-color: $background-secondary-color;
      border-radius: 20px;
      font-size: 20px;
      font-weight: bold;
      padding: 10px 0;
    }

    &.extra-info {
      background-color: #E7EBEE;
      border-radius: 0 0 20px 20px;
      margin-top: -10px;
      padding: 10px 20px;

      .bubble {
        &:last-child {
          margin-right: 0;
        }

        background-color: white;
        border-radius: 100%;
        color: $text-color;
        display: inline-block;
        height: 50px;
        line-height: 50px;
        margin-right: 7.5px;
        text-align: center;
        width: 50px;
      }
    }

    &.top {
      margin-top: 20px;

      @media screen and (max-width: 680px) {
        .cell.status {
          display: none;
        }
      }
    }

    .cell {
      &.full {
        width: 100%;
      }

      &.summary {
        &.cost {
          width: 20%;
        }

        &.image {
          .raffle-image {
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 35px;
            height: 75px;
            margin: 0 auto;
            width: 75%;
          }

          padding: 2.5px;
          width: 20%;

          @media screen and (max-width: 725px) {
            display: none;
          }
        }

        &.raffle-name {
          a {
            float: left;
            text-align: left;
            text-decoration: none;
            text-transform: uppercase;

            &.reveal {
              text-align: center;
              width: 100%;

              @media screen and (max-width: 555px) {
                text-overflow: unset;
                white-space: unset;
              }
            }

            @media screen and (max-width: 750px) {
              padding: 0 20px;
              width: 100%;
            }

            @media screen and (max-width: 555px) {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          &.summary-reveal {
            width: 30%;

            p {
              font-weight: normal;
              padding: 0 20px;
              width: 100%;
            }

            @media screen and (max-width: 725px) {
              width: 50%;
            }
          }

          margin: auto 0;
          width: 40%;

          @media screen and (max-width: $mobile-break) {
            width: 60%;
          }

          @media screen and (max-width: 725px) {
            width: 80%;
          }
        }

        &.status.reveal {
          float: right;
          width: 20%;

          @media screen and (max-width: 750px) {
            flex-basis: 100%;
          }
        }

        &.tickets {
          .ticket-bubble {
            &:last-child {
              margin-right: 0;
            }

            &.winner {
              background-color: #ffd700;
            }

            background-color: white;
            border-radius: 100%;
            color: $text-color;
            display: inline-block;
            height: 50px;
            line-height: 50px;
            margin-right: 7.5px;
            width: 50px;
          }

          &.reveal {
            width: 30%;

            @media screen and (max-width: 750px) {
              width: 50%;
            }
          }

          &::-webkit-scrollbar {
            height: 5px;
            margin-top: 5px;
          }
        
          &::-webkit-scrollbar-thumb {
            background-color: grey;
            border-radius: 20px;
          }

          overflow-x: scroll;
          width: 20%;
          white-space: nowrap;
        }

        p {
          font-size: 18px;
          margin-bottom: 0;
        }
      }

      &.ticket-number .ticket-bubble {
        background-color: white;
        border-radius: 100%;
        display: block;
        height: 50px;
        line-height: 50px;
        margin: auto;
        width: 50px;
      }

      @media screen and (max-width: 680px) {
        &.status {
          flex-basis: 100%;
        }

        &.ticket-number {
          width: 20%;
        }
      }

      margin: auto;
      text-align: center;
      width: 33%;
    }

    .not-won {
      border: $background-color 2px solid;
      border-radius: 35px;
      padding: 1px 30px;

      @media screen and (max-width: 680px) {
        padding: 1px 20px;
      }
    }

    .won {
      background-color: #73CF83;
      border: #73CF83 2px solid;
      border-radius: 35px;
      color: white;
      padding: 1px 30px 1px 10px;
      position: relative;

      svg {
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    row-gap: 25px;
    width: 100%;
  }

  .scrollable {
    padding-right: 10px;
    max-height: 800px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 5px;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: grey;
      border-radius: 20px;
    }
  }
  
  padding: 20px;
}

.lucky-dip-container {
  .free-entry {
    float: left;
    line-height: 37px;
  }
}

.lucky-dip-btn {
  &.amount {
    div {
      display: inline-block;
    }

    &.instant-wins {
      margin-bottom: 10px;
      margin-top: 30px;
    }

    .add-subtract {
      color: grey;
      height: 17.5px;
      width: 17.5px;
      float: left;
      margin-top: 4px;
    }

    background-color: #f3f8fc;
    color: $text-color !important;
    margin-right: 15px;
    padding: 10px 20px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  &.answer {
    &.current {
      background-color: #102964;
      color: white !important;
    }

    display: block;
    color: $text-color !important;
    background-color: darken($background-secondary-color, 10%);
    margin: 20px auto;
    width: 40%;
  }

  &.back {
    background-color: #CACACA;
    margin-bottom: 0;
  }

  &.confirm {
    padding: 7.5px 10px;
  }

  &.continue {
    display: block;
    margin: 50px auto 0;
    width: 40%;
  }

  &.inline {
    display: inline-block;
  }

  &.login {
    display: block;
    margin: 0 auto;
    text-align: center;
    width: 100px;
  }

  background-color: $background-color;
  color: white !important;
  font-weight: bold;
  margin: 1.3em 0;
}

.add-to-cart-btn-container {
  .add-to-cart-inner {
    padding: 20px 40px;
    margin: 0 auto;
    max-width: $grid-inner-max-width;
    position: relative;
  }

  &.sticky {
    bottom: 0;
    left: 50%;
    position: fixed;
    transform: translateX(-50%);
  }

  background-color: white;
  border-radius: 25px;
  left: 0;
  padding: 20px;
  width: 100%;
}

.sticky-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 99999;
} 

.add-to-cart-text {
  color: $text-color;
  font-size: 18px;
  margin-bottom: 15px;
  text-align: center;
}

.add-to-cart-btns {
  float: right;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.add-to-cart-btn {
  display: inline-block;
  border-radius: 50px;
  font-size: 1.05rem;
  padding: 10px 20px;
  position: relative;
  font-weight: bold;

  &.cost {
    background-color: white;
    color: $text-color !important;
    border: $background-color solid 2px !important;
    margin-right: 30px;
    cursor: default;
  }

  &.pay {
    background-color: $background-color;
    color: white !important;
  }

  .quantity {
    background: white;
    color: #222;
    position: absolute;
    right: 7px;
    top: 6px;
    width: 45px;
    height: 30px;
    border-radius: 100px;
    line-height: 30px;
    font-size: 1rem;
    font-weight: bold;
  }
}

.lucky-dip {
  font-size: 1rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: normal;
  text-decoration: none;
}

/*
.raffle-ticket-selector-header {
  position: relative;

  .selector-btn {
    background: $background-secondary-color;
    border-radius: 13px 13px 0 0;
    height: 57px;
    margin: 0 5px;

    &.selected {
      background: transparent linear-gradient(0deg, #f8036d 0%, #ff313d 100%) 0% 0% no-repeat
        padding-box;
      color: white;
    }
  }
}
*/

.lucky-dip-bubble {
  background: $background-color;
  width: 50px;
  height: 30px;
  text-align: center;
  border-radius: 30px;
  top: -10px;
  cursor: pointer;
}

.lucky-dip-bubble-text {
  text-align: center;
  color: white;
  font-weight: bold;
  line-height: 30px !important;
}

.lucky-dip-slider-track.lucky-dip-slider-track-0 {
  background: $background-color;
  height: 100%;
  border-radius: 5px;
}

.lucky-dip-slider {
  height: 10px;
  border-radius: 5px;
  width: 100%;
  margin: 10px auto;
  background-color: darken($background-secondary-color, 10%);
}

.other-raffles-header {
  color: #102964;
  text-align: center;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.raffle-ticket-selector-main {
  &.below-white {
    background-color: $background-secondary-color;
    border: none;
  }

  padding: 0;
}

.ticket-selector-container {
  .ticket-selector-header {
    h1, h2, h3 {
      color: #102964;
    }

    &.instant-wins h1 {
      display: block;
      text-align: center;
      width: 100%;
    }

    &.instant-wins h2 {
      font-size: 18px;
      font-weight: normal;
    }

    h1 {
      display: inline-block;
      width: 70%;
    }

    h2 {
      font-size: 2em;
      text-align: center;
    }

    .raffle-selectans {
      margin: 30px 0 20px 0;
      text-align: center;
    }

    padding: 0 10px 20px 10px;
    margin-bottom: 10px;
  }

  background-color: white;
  border-radius: 20px;
  padding: 20px;
  width: 100%;
}

.ticket-selector-letters-col {
  width: calc(100% - 200px);
}

.ticket-selector-letters {
  overflow-x: scroll;
  padding-bottom: 5px;
  white-space: nowrap;

  &::-webkit-scrollbar {
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: grey;
    border-radius: 20px;
  }
}

.ticket-selector-numbers {
  max-height: 635px;
  padding-top: 5px;
  position: relative;
  overflow-y: scroll;

  &.has-letters {
    padding-top: 20px;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: grey;
    border-radius: 20px;
  }
}

.raffle-answer-question {
  position: fixed;
  top: 200px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  margin: 50px auto;
  max-width: 600px;
  width: 100%;
  background: $background-secondary-color;
  box-shadow: 0 0 100px rgba(#fff, 0.2);
  border-radius: 10px;
  padding: 40px 50px;
  text-align: center;

  .close-btn {
    position: absolute;
    top: 0;
    right: 20px;
    background: none;
    border: 0;
    appearance: none;
    color: white;
  }

  h2 {
    font-size: 1.4rem;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 20px;
  }

  .raffle-answer {
    padding-top: 10px;

    input {
      display: inline-block;
    }

    label {
      font-size: 1rem;
      display: inline-block;
      margin-left: 10px;
      color: white;
    }
  }

  button {
    margin-top: 20px;
  }
}

@media screen and (max-width: $mobile-break + 130) {
  .raffle-info .raffle-specifics .price-btn {
    small {
      margin: 0 5px;
    }

    &.sale {
      padding: 10px 20px 10px 125px;
    }

    .ticket-prices p {
      color: white;
      margin: auto 0;
    }

    .quantity {
      top: 50%;
      transform: translateY(-50%);
    }

    left: 50%;
    transform: translateX(-50%);
    width: auto;
  }
}

@media screen and (max-width: $mobile-break) {
  .add-to-cart-btns {
    float: unset;
    text-align: center;
  }
  
  .live-draw-section {
    height: 350px !important;
    left: 50%;
    transform: translateX(-50%);
    width: 650px !important;

    .image-livestream {
      height: 350px !important;
    }
  }

  .lucky-dip-btn {
    &.amount {
      display: inline-block;
      text-align: center;

      .add-subtract {
        width: 25px;
        height: 25px;
      }
    }

    &.answer {
      display: block;
      width: 100%;
    }

    &.confirm {
      display: inline-block;
      text-align: center;
    }

    &.continue {
      display: block;
      margin: 50px auto 0;
      width: 100%;
    }

    .quick-pick {
      font-size: 22.5px;
    }

    display: block;
    margin: 0 auto 20px;
  }

  .raffle-card-container {
    margin: 0 auto;
    max-width: 50%;
    
    .raffle-card {
      width: 100%;
    }
  }

  .raffle-info .raffle-specifics {
    .price-btn {
      small {
        margin: 0 5px;
      }

      &.sale {
        padding: 10px 20px 10px 125px;
      }

      .ticket-prices p {
        color: white;
        margin: auto 0;
      }

      .quantity {
        top: 50%;
        transform: translateY(-50%);
      }

      left: 50%;
      transform: translateX(-50%);
      width: auto;
    }

    .ticket-stats {
      text-align: center;
      padding-bottom: 20px;
    }

    .tickets-overview {
      .percentage-sales {
        &.charity-image {
          height: 100px;
          left: unset;
        }

        position: relative;
        top: unset;
        transform: unset;
        margin: 0 auto;
        padding: 10px 0;
        width: 100%;
      }
    
      .progress-bar {
        width: 27.5%;
        display: block;
        margin: 0 auto;
      }

      min-height: 100px;
    }
  }

  .testimonial-slider .carousel-arrow {
    &.carousel-prev {
      left: 60px !important;
    }

    &.carousel-next {
      right: 60px !important;
    }
  }

  .sort-dropdown-container {
    text-align: center;
    margin-bottom: 50px;

    .sort-dropdown {
      right: 50%;
      transform: translateX(50%);

      &:before {
        right: 90px;
      }
    }
  }

  .ticket-selector-container .ticket-selector-header h1 {
    display: block;
    width: 100%;
    text-align: center;
  }

  .ticket-selector-numbers {
    text-align: center;
  }
}

@media screen and (max-width: 812px) {
  .raffle-card-container {
    margin: 0 auto;
    max-width: 100%;

    .raffle-card {
      width: 80%;

      &.also-interested {
        width: 90%;
      }
    }
  }

  .raffle-info .raffle-specifics .tickets-overview .progress-bar {
    width: 50%;
  }

  .testimonial-slider .carousel-arrow {
    &.carousel-prev {
      left: 0px !important;
    }

    &.carousel-next {
      right: 0px !important;
    }
  }
}

@media screen and (max-width: 772px) {
  .live-draw-section {
    height: 225px !important;
    width: 450px !important;

    .image-livestream {
      height: 225px !important;
    }
  }
}

@media screen and (max-width: 688px) {
  .raffle-info .raffle-specifics {
    .price-btn.sale {
      .ticket-prices {
        p {
          position: absolute;
          right: 50%;
          top: 75%;
          transform: translate(50%, -50%);
        }

        small {
          margin: unset;
        }

        text-align: center;
      }

      border-radius: 25px;
      min-height: 100px;
      width: 200px;
    }

    .price-btn {
      .quantity.sale {
        position: absolute;
        top: 0;
        width: 82.5%;
      }
    }

    .tickets-overview .progress-bar {
      width: 75%;
    }
  }
}

@media screen and (max-width: 452px) {
  .add-to-cart-btn.cost {
    margin-bottom: 10px;
    margin-right: unset;
  }

  .live-draw-section {
    height: 165px !important;
    width: 325px !important;

    .image-livestream {
      height: 165px !important;
    }
  }

  .raffle-card {
    .raffle-card-contents {
      .tickets-bar-container {
        width: 55%;
      }
    }
  }

  .raffle-slider {
    .raffle-image {
      height: 300px;
    }

    .slick-track {
      height: 300px;
    }
  }
}
.live-draw-stream {
  display: block;
  overflow: hidden;
  max-width: 1300px;
  max-height: 730px;
  width: 80vw;
  margin: 0 auto;
  height: 45vw;
  background: black;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 13px;
  background-size: cover;
  background-position: 50% 50%;
}

.previous-draws {
  .livestreams {
    &::-webkit-scrollbar {
      height: 5px;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: grey;
      border-radius: 20px;
    }

    .live-draw-stream {
      display: inline-block;
      width: 450px;
      height: 252px;
      margin-right: 25px;
    }

    overflow-x: scroll;
    padding-bottom: 10px;
  }

  background-color: $background-secondary-color;
  border-radius: 25px;
  margin-bottom: 40px;
  padding: 25px;
  white-space: nowrap;
}

@media screen and (max-width: 655px) {
  .live-draw-stream {
    width: 92.5%;
    height: 50vw;
  }

  .previous-draws .livestreams .live-draw-stream {
    width: 65vw;
    height: 35vw;
  }
}
.program-section {
  margin: 0 auto 40px auto;
  text-align: center;
  width: 100%;

  &.no-bottom {
    margin-bottom: 0;
  }

  p {
    &.guide {
      margin: 20px auto 0;
      max-width: 300px;
    }

    font-weight: bold;
    letter-spacing: unset;
    line-height: 20px;
    margin-bottom: 0;
  }

  .btn {
    margin-top: 20px;
    padding: 15px 25px;
  }

  .input {
    color: $text-color;
    margin-top: 20px;
    max-width: 650px;
    text-align: center;
    width: 80%;
  }

  .tip {
    color: $background-color;
    font-weight: bold;
  }
}

.referral-guide {
  &.man {
    background-image: url("../../../img/man.svg");

    &.after::after {
      background-color: $text-light-color;
      border-radius: 25px;
      content: "";
      padding: 2px 40px 2px;
      position: absolute;
      right: -25px;
      top: 50%;
    }
  }

  &.tablet {
    background-image: url("../../../img/tablet.svg");

    &.after::after {
      background-color: $text-light-color;
      border-radius: 25px;
      content: "";
      padding: 2px 40px 2px;
      position: absolute;
      right: -75px;
      top: 50%;
    }
  }

  &.coins {
    background-image: url("../../../img/coins.svg");
  }

  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  height: 125px;
}

@media screen and (max-width: $mobile-break) {
  .referral-guide {
    &.man.after::after {
      bottom: -100px;
      padding: 20px 2px;
      right: 50%;
      top: unset;
    }

    &.tablet.after::after {
      bottom: -120px;
      padding: 20px 2px;
      right: 50%;
      top: unset;
    }
  }
}
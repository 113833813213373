.cta-slab p.order-text {
  &.no-top {
    margin-top: unset;
  }

  line-height: 1.1rem;
  margin-bottom: 5px;
  margin-top: 40px;
  text-align: center;
}

.order-confirmation-summary {
  background-color: white;
  border-radius: 20px;
  color: $text-color;
  padding: 20px;

  .summary-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    .summary-col {
      .black {
        color: black;
        font-size: 18px;
        font-weight: normal;
      }

      &.cost {
        h2 {
          color: $text-color;
          font-size: 18px;
          text-align: right;
        }

        width: 20%;
      }

      width: 70%;
    }
  }
}

.trustpilot {
  background-image: url("https://pryze-static.s3.eu-west-2.amazonaws.com/img/review_trustpilot-c1fadb51-b0c1-4b4b-8c4b-cf9da795507e.png");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  height: 75px;
  margin: auto;
  width: 200px;
}